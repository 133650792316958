<template>
  <div class="future">
    <section class="header">
      <div>
        <h5>Data</h5>
        <h5>Descrição</h5>
      </div>
      <h5>Valor</h5>
    </section>
    <section class="content">
      <div v-for="statement in data" :key="statement.id">
        <div v-if="statement.items && statement.items.length > 0">
          <div class="statement-header">
            <span>{{ `Fatura vencimento - ${formatDate(statement.due_date)}` }}</span>
          </div>
          <payment-selector-list-item
            v-for="item in statement.items"
            :key="item.id"
            :data="item"
            :action="hasActions"
            :future="true"
            @onClick="handleListItemClick"
          />
        </div>
      </div>
    </section>
    <payment-selector-list-modal
      :active="showModal"
      :data="selectedInstallment"
      @onClose="handleModalClose"
      @onSubmit="handleModalSubmit"
    />
  </div>
</template>

<script>
import PaymentSelectorListItem from "./PaymentSelectorListItem";
import PaymentSelectorListModal from "./PaymentSelectorListModal";
import moment from "moment";
export default {
  components: {
    PaymentSelectorListItem,
    PaymentSelectorListModal
  },
  props: {
    statements: {
      type: [Array, null],
      default: () => []
    },
    deselectedItem: {
      type: [Object, null],
      default: () => null
    },
    hasActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: [],
      selectedInstallment: [],
      showModal: false
    };
  },
  watch: {
    deselectedItem: function(newValue) {
      this.handleSelect(newValue);
    }
  },
  mounted() {
    if (this.statements && this.statements.length > 0) {
      this.data = this.statements
        .map(statement => ({
          ...statement,
          items: statement.items.map(item => ({
            ...item,
            statement_id: statement.id,
            selected: false,
            future: true
          }))
        }))
        .filter(statement => {
          const nextMonth = moment().add(1, "month");
          const statementDate = moment(statement.due_date);
          return statementDate.isAfter(nextMonth);
        });
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    handleListItemClick(item) {
      this.selectedInstallment = [item];
      this.showModal = true;
    },
    handleModalClose() {
      this.selectedInstallment = [];
      return (this.showModal = false);
    },
    handleSelect(data, callback = f => f) {
      const statementIndex = this.data.findIndex(
        item => item.id === data.statement_id
      );
      if (statementIndex !== -1) {
        const itemIndex = this.data[statementIndex].items.findIndex(
          item => item.id === data.id
        );
        if (itemIndex !== -1) {
          this.data[statementIndex].items[itemIndex].selected = !this.data[
            statementIndex
          ].items[itemIndex].selected;
          callback();
        }
      }
    },
    handleModalSubmit(data) {
      this.handleSelect(data, () => this.$emit("onClick", data));
    }
  }
};
</script>

<style lang="scss">
.future {
  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 6px 60px 6px 0;
    h5 {
      font-family: Montserrat;
      font-weight: 700;
      color: $preto;
      font-size: 13px;
    }
    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      h5:first-child {
        padding: 0 4px;
        min-width: 80px;
      }
      h5 + h5 {
        margin-left: 12px;
      }
    }
    @media screen and (max-width: 320px) {
      padding: 6px 14px 6px 0;
    }
  }
  .payment-selector-list-item {
    .selector-button-amount {
      top: 2px !important;
    }
  }
}
.statement-header {
  width: 100%;
  background-color: rgba(108, 115, 124, 0.3);
  padding: 0 4px;
  font-size: 13px;
  span {
    font-family: Montserrat;
    font-weight: 700;
    color: $preto;
  }
}
</style>
